import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Img, { FluidObject } from 'gatsby-image';
import { graphql } from 'gatsby';
import { white } from 'shared/colors';
import { BREAKPOINTS } from 'shared/constants';
import Slider from 'react-slick';
import './Article.css';

interface Props {
  data: {
    contentfulArticleObject: {
      id: string;
      slug: string;
      name: string;
      description: string;
      subheader: string;
      ctaText: string;
      mainImage: {
        fluid: FluidObject;
      };

      contentItems: {
        id: string;
        largeText: {
          largeText: string;
        };
        smallText: {
          smallText: string;
        };
        quote: {
          quote: string;
        };
        fullWidthImage: {
          fluid: FluidObject;
        };
        leftImage: {
          fluid: FluidObject;
        };
        rightImage: {
          fluid: FluidObject;
        };
        centerImage: {
          fluid: FluidObject;
        };
        imageCollection: {
          id: string;
          fluid: FluidObject;
        }[];
        sideBySide: {
          id: string;
          fluid: FluidObject;
        }[];
        fourShot: {
          id: string;
          fluid: FluidObject;
        }[];
        link: string;
        shopLinkSide: boolean;
        authour: string;
        youtubeId: string;
        threedmodel: string;
      }[];
    };

    contentfulArticleContent: {
      id: string;
      title: string;
      largeText: {
        largeText: string;
      };
      smallText: {
        smallText: string;
      };
      quote: {
        quote: string;
      };
      fullWidthImage: {
        fluid: FluidObject;
      };
      leftImage: {
        fluid: FluidObject;
      };
      rightImage: {
        fluid: FluidObject;
      };
      centerImage: {
        fluid: FluidObject;
      };
      imageCollection: {
        fluid: FluidObject;
      }[];
      sideBySide: {
        id: string;
        fluid: FluidObject;
      }[];
      fourShot: {
        id: string;
        fluid: FluidObject;
      }[];
      link: string;
      shopLinkSide: boolean;
      authour: string;
      youtubeId: string;
    };
  };

  location: {
    pathname: string;
  };
}

const Article = ({ data }: Props) => {
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.sectionOne)}>
        <Img
          className={css(styles.bgImage)}
          alt="Article BG"
          fluid={data.contentfulArticleObject.mainImage.fluid}
        />
        <div className={css(styles.bgImageFilter)}></div>
        <div className={css(styles.textWrapper)}>
          <p className={css(styles.subheader)}>
            {data.contentfulArticleObject.subheader}
          </p>
          <h2 className={css(styles.title)}>
            {data.contentfulArticleObject.name}
          </h2>
        </div>
      </div>

      {data.contentfulArticleObject.contentItems.map(
        ({
          largeText,
          smallText,
          quote,
          fullWidthImage,
          leftImage,
          rightImage,
          centerImage,
          imageCollection,
          link,
          authour,
          shopLinkSide,
          sideBySide,
          fourShot,
          youtubeId,
          threedmodel,
        }) => {
          if (largeText) {
            return (
              <div className={css(styles.contentWrapper)}>
                <div className={css(styles.largeTextWrapper)}>
                  <p className={css(styles.largeText)}>{largeText.largeText}</p>
                </div>
              </div>
            );
          } else if (smallText) {
            return (
              <div className={css(styles.contentWrapper)}>
                <div className={css(styles.smallTextWrapper)}>
                  <p className={css(styles.smallText)}>{smallText.smallText}</p>
                </div>
              </div>
            );
          } else if (quote) {
            return (
              <div className={css(styles.contentWrapper)}>
                <div className={css(styles.quoteWrapper)}>
                  <p className={css(styles.quote)}>{quote.quote}</p>
                  <p className={css(styles.authour)}>{authour}</p>
                </div>
              </div>
            );
          } else if (fullWidthImage) {
            return (
              <div className={css(styles.contentWrapper)}>
                <Img
                  className={css(styles.fullWidthImage)}
                  alt="Article IMG"
                  fluid={fullWidthImage.fluid}
                />
              </div>
            );
          } else if (threedmodel && rightImage) {
            return (
              <div className={css(styles.contentWrapper)}>
                <div className={css(styles.imageSideBySideWrapper)}>
                  <iframe
                    src={threedmodel}
                    title="contains a 3D jar of monogram product"
                    className={css(styles.embedWebGl)}
                  />
                  <Img
                    className={css(styles.imageSideBySideImg)}
                    alt="Article IMG"
                    fluid={rightImage.fluid}
                  />
                  {link && authour && (
                    <a
                      className={css(styles.shopLinkLeftSmall)}
                      href={link}
                      target="_blank"
                    >
                      {authour}
                    </a>
                  )}
                </div>
              </div>
            );
          } else if (threedmodel && leftImage) {
            return (
              <div className={css(styles.contentWrapper)}>
                <div
                  className={css([
                    styles.imageSideBySideWrapper,
                    styles.revertOnMobile,
                  ])}
                >
                  <Img
                    className={css(styles.imageSideBySideImg)}
                    alt="Article IMG"
                    fluid={leftImage.fluid}
                  />
                  <iframe
                    src={threedmodel}
                    title="contains a 3D jar of monogram product"
                    className={css(styles.embedWebGl)}
                  />
                  {link && authour && (
                    <a
                      className={css(styles.shopLinkRightSmall)}
                      href={link}
                      target="_blank"
                    >
                      {authour}
                    </a>
                  )}
                </div>
              </div>
            );
          } else if (leftImage) {
            return (
              <div className={css(styles.contentWrapper)}>
                <Img
                  className={css(styles.leftImage)}
                  alt="Article IMG"
                  fluid={leftImage.fluid}
                />
                <a className={css(styles.shopLinkLeft)} href={link}>
                  {authour}
                </a>
              </div>
            );
          } else if (rightImage) {
            return (
              <div className={css(styles.contentWrapper)}>
                <Img
                  className={css(styles.rightImage)}
                  alt="Article IMG"
                  fluid={rightImage.fluid}
                />
              </div>
            );
          } else if (centerImage) {
            return (
              <div className={css(styles.contentWrapper)}>
                <Img
                  className={css(styles.centerImage)}
                  alt="Article IMG"
                  fluid={centerImage.fluid}
                />
              </div>
            );
          } else if (imageCollection) {
            return (
              <div className={css(styles.contentWrapper)}>
                <div className={css(styles.imageCollectionWrapper)}>
                  <div className="article-slider-wrapper">
                    <Slider
                      accessibility={true}
                      focusOnSelect={true}
                      infinite={true}
                      speed={500}
                      arrows={false}
                      dots={false}
                      swipe={true}
                      autoplay={false}
                      centerPadding={'0px'}
                      slidesToShow={1}
                      variableWidth={true}
                    >
                      {imageCollection.map(({ fluid, id }) => (
                        <Img
                          key={id}
                          fluid={fluid}
                          className={css(styles.slickImg)}
                        />
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            );
          } else if (sideBySide && shopLinkSide) {
            return (
              <div className={css(styles.contentWrapper)}>
                <div className={css(styles.imageSideBySideWrapper)}>
                  {sideBySide.map(({ fluid, id }) => {
                    return (
                      <Img
                        fluid={fluid}
                        alt="Article IMG"
                        className={css(styles.imageSideBySideImg)}
                        imgStyle={{
                          objectFit: 'cover',
                          objectPosition: '50% 50%',
                        }}
                        style={{}}
                        key={id}
                      />
                    );
                  })}
                  ;
                  <a
                    className={css(styles.shopLinkLeft)}
                    href={link}
                    target="_blank"
                  >
                    {authour}
                  </a>
                </div>
              </div>
            );
          } else if (sideBySide && link && authour) {
            return (
              <div className={css(styles.contentWrapper)}>
                <div className={css(styles.imageSideBySideWrapper)}>
                  {sideBySide.map(({ fluid, id }) => {
                    return (
                      <Img
                        fluid={fluid}
                        alt="Article IMG"
                        className={css(styles.imageSideBySideImg)}
                        imgStyle={{
                          objectFit: 'cover',
                          objectPosition: '50% 50%',
                        }}
                        style={{}}
                        key={id}
                      />
                    );
                  })}
                  ;
                  <a
                    className={css(styles.shopLinkRight)}
                    href={link}
                    target="_blank"
                  >
                    {authour}
                  </a>
                </div>
              </div>
            );
          } else if (sideBySide) {
            return (
              <div className={css(styles.contentWrapper)}>
                <div className={css(styles.imageSideBySideWrapper)}>
                  {sideBySide.map(({ fluid }) => (
                    <Img
                      fluid={fluid}
                      alt="Article IMG"
                      className={css(styles.imageSideBySideImg)}
                      imgStyle={{
                        objectFit: 'cover',
                        objectPosition: '50% 50%',
                      }}
                      style={{}}
                    />
                  ))}
                  ;
                </div>
              </div>
            );
          } else if (fourShot) {
            return (
              <div className={css(styles.contentWrapper)}>
                <div className={css(styles.imageFourShotWrapper)}>
                  {fourShot.map(({ fluid }) => (
                    <Img
                      fluid={fluid}
                      alt="Article IMG"
                      className={css(styles.imageFourShotImg)}
                      imgStyle={{
                        objectFit: 'cover',
                        objectPosition: '50% 50%',
                      }}
                      style={{}}
                    />
                  ))}
                </div>
              </div>
            );
          } else if (youtubeId) {
            const youtubeUrl = `https://www.youtube.com/embed/${youtubeId}?rel=0?modestbranding=1`;

            return (
              <div className={css(styles.contentWrapper)}>
                <div className={css(styles.videoEmbed)}>
                  <iframe
                    className={css(styles.iFrame)}
                    width="560"
                    height="315"
                    src={youtubeUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            );
          }

          return null;
        }
      )}
    </div>
  );
};

export default Article;

const styles = StyleSheet.create({
  container: {
    height: 'auto',
    width: '100vw',
    paddingTop: '120px',
    [BREAKPOINTS.TABLET]: {
      paddingTop: '90px',
    },
  },
  sectionOne: {
    position: 'relative',
    width: '100vw',
    height: '100vw',
    display: 'flex',
    flex: 'auto',
    overflow: 'hidden',
    borderTop: `1px solid ${white}`,
    [BREAKPOINTS.TABLET]: {
      flexDirection: 'column',
      flex: '1',
    },
  },
  bgImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  bgImageFilter: {
    position: 'absolute',
    width: '100%',
    height: '50%',
    backgroundImage:
      'linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))',
  },
  textWrapper: {
    position: 'absolute',
    top: 'calc(50vh - 60px)',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',

    [BREAKPOINTS.TABLET]: {
      top: '60px',
      transform: 'translate(-50%,0%)',
      width: 'calc(100vw - 40px)',
    },
  },
  subheader: {
    position: 'relative',
    fontSize: 12,
    fontFamily: 'Titling Gothic FB Wide',
    color: white,
    lineHeight: '12px',
    textTransform: 'uppercase',
    paddingBottom: '20px',
    [BREAKPOINTS.TABLET]: {
      fontSize: 8,
    },
  },
  title: {
    fontFamily: 'Titling Gothic FB Compressed Standard',
    textTransform: 'uppercase',
    fontSize: '70px',
    lineHeight: '70px',
    color: white,
    [BREAKPOINTS.MOBILE]: {
      fontSize: 40,
      lineHeight: 1,
    },
  },
  contentWrapper: {
    width: '100vw',
    height: 'auto',
    position: 'relative',

    ':last-of-type': {
      paddingBottom: '100px',

      [BREAKPOINTS.MOBILE]: {
        paddingBottom: '80px',
      },
    },
  },
  largeTextWrapper: {
    color: white,
    textTransform: 'uppercase',
    fontFamily: 'Titling Gothic FB Compressed',
    fontWeight: 'normal',
    fontSize: 50,
    lineHeight: 1.2,
    zIndex: 1,
    paddingTop: '100px',
    textAlign: 'center',
    width: '50%',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',

    [BREAKPOINTS.TABLET]: {
      left: 0,
      width: '100vw',
      top: 0,
      padding: '0 40px',
      paddingTop: '80px',
      transform: 'initial',
      fontSize: '30px',
      lineHeight: 1.2,
    },
  },
  smallTextWrapper: {
    paddingTop: '100px',
    width: '50%',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',

    [BREAKPOINTS.TABLET]: {
      left: 0,
      width: '100vw',
      top: 0,
      padding: '0 40px',
      paddingTop: '80px',
      transform: 'initial',
    },
  },
  smallText: {
    fontFamily: 'Titling Gothic FB Normal Regular',
    lineHeight: 1.2,
    color: white,
    position: 'relative',
    fontSize: 12,

    [BREAKPOINTS.TABLET]: {
      lineHeight: 1.5,
    },
  },
  quoteWrapper: {
    paddingTop: '20px',
    width: 'calc(50% - 20px)',
    position: 'relative',
    left: '50%',
    textAlign: 'left',

    [BREAKPOINTS.TABLET]: {
      left: 0,
      width: '100vw',
      top: 0,
      padding: '0 40px',
      paddingTop: '80px',
      transform: 'initial',
    },
  },
  quote: {
    fontFamily: 'Titling Gothic FB Compressed',
    textTransform: 'uppercase',
    fontSize: 50,
    lineHeight: 1.2,
    color: white,

    [BREAKPOINTS.TABLET]: {
      fontSize: '30px',
      lineHeight: 1.2,
    },
  },
  authour: {
    fontFamily: 'Titling Gothic FB Normal Regular',
    lineHeight: 1.2,
    color: white,
    fontSize: 12,
    marginTop: '10px',
  },
  fullWidthImage: {
    marginTop: '100px',
    width: '100%',
    position: 'relative',

    [BREAKPOINTS.MOBILE]: {
      marginTop: '80px',
    },
  },
  leftImage: {
    marginTop: '100px',
    width: '50%',
    position: 'relative',
    left: 0,

    [BREAKPOINTS.MOBILE]: {
      width: 'calc(100% - 80px)',
      position: 'relative',
      left: '40px',
      marginTop: '80px',
    },
  },
  shopLinkLeft: {
    fontFamily: 'Titling Gothic FB Normal Regular',
    lineHeight: 1.2,
    color: white,
    fontSize: 12,
    marginTop: '20px',
    position: 'absolute',
    bottom: '20px',
    left: '20px',

    [BREAKPOINTS.MOBILE]: {
      width: 'calc(100vw - 80px)',
      left: '50%',
      bottom: '75%',
      padding: '20px',
      border: '1px solid white',
      textAlign: 'center',
      transform: 'translate(-50%,-50%)',
      textTransform: 'uppercase',
      fontFamily: 'Titling Gothic FB Wide',
      fontWeight: 500,
      textDecoration: 'none',
    },
  },

  rightImage: {
    marginTop: '100px',
    width: '50%',
    position: 'relative',
    left: '50%',

    [BREAKPOINTS.MOBILE]: {
      width: 'calc(100% - 80px)',
      position: 'relative',
      left: '40px',
      marginTop: '80px',
    },
  },
  shopLinkRight: {
    fontFamily: 'Titling Gothic FB Normal Regular',
    lineHeight: 1.2,
    color: white,
    fontSize: 12,
    marginTop: '20px',
    position: 'absolute',
    bottom: '20px',
    right: '20px',

    [BREAKPOINTS.MOBILE]: {
      width: 'calc(100vw - 80px)',
      left: '50%',
      bottom: '25%',
      padding: '20px',
      border: '1px solid white',
      textAlign: 'center',
      transform: 'translate(-50%,-50%)',
      textTransform: 'uppercase',
      fontFamily: 'Titling Gothic FB Wide',
      fontWeight: 500,
      textDecoration: 'none',
    },
  },
  shopLinkLeftSmall: {
    fontFamily: 'Titling Gothic FB Normal Regular',
    lineHeight: 1.2,
    color: white,
    fontSize: 12,
    marginTop: '20px',
    position: 'absolute',
    bottom: '20px',
    left: '20px',

    [BREAKPOINTS.MOBILE]: {
      left: '50%',
      bottom: '75%',
      padding: '12px 20px',
      border: '1px solid white',
      textAlign: 'center',
      transform: 'translate(-50%,0%)',
      textTransform: 'uppercase',
      fontFamily: 'Titling Gothic FB Wide',
      fontWeight: 500,
      textDecoration: 'none',
      fontSize: 10,
      color: 'white',
      background: 'black',
    },
  },
  shopLinkRightSmall: {
    fontFamily: 'Titling Gothic FB Normal Regular',
    lineHeight: 1.2,
    color: white,
    fontSize: 12,
    marginTop: '20px',
    position: 'absolute',
    bottom: '20px',
    right: '20px',

    [BREAKPOINTS.MOBILE]: {
      lleft: '50%',
      bottom: '75%',
      padding: '12px 10px',
      border: '1px solid white',
      textAlign: 'center',
      transform: 'translate(-50%,0%)',
      textTransform: 'uppercase',
      fontFamily: 'Titling Gothic FB Wide',
      fontWeight: 500,
      textDecoration: 'none',
      fontSize: 10,
      whiteSpace: 'nowrap',
      color: 'white',
      background: 'black',
    },
  },
  centerImage: {
    marginTop: '100px',
    width: '50%',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',

    [BREAKPOINTS.MOBILE]: {
      width: 'calc(100% - 80px)',
      position: 'relative',
      left: '40px',
      transform: 'translateX(0%)',
      marginTop: '80px',
    },
  },
  imageCollectionWrapper: {
    marginTop: '100px',
    position: 'relative',
    maxHeight: 'auto',
    overflow: 'hidden',
    cursor: 'ew-resize',

    [BREAKPOINTS.MOBILE]: {
      marginTop: '80px',
    },
  },
  slickImg: {
    position: 'relative',
    marginLeft: '20px',
    width: '70vw',
  },
  flexSpace: {
    position: 'relative',
    width: '20px',
    flex: 'none',
  },
  imageSideBySideWrapper: {
    marginTop: '100px',
    position: 'relative',
    width: '100%',
    display: 'flex',
    overflowY: 'hidden',
    overflowX: 'scroll',

    [BREAKPOINTS.MOBILE]: {
      marginTop: '80px',
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
    },
  },
  revertOnMobile: {
    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column-reverse',
    },
  },
  imageSideBySideImg: {
    position: 'relative',
    width: '50vw',

    [BREAKPOINTS.MOBILE]: {
      width: '100vw',
    },
  },
  imageFourShotWrapper: {
    marginTop: '100px',
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'hidden',
    overflowX: 'scroll',

    [BREAKPOINTS.MOBILE]: {
      marginTop: '80px',
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
    },
  },
  imageFourShotImg: {
    flex: '50%',
    position: 'relative',
    width: '50vw',

    [BREAKPOINTS.MOBILE]: {
      flex: 1,
      width: '100vw',
    },
  },
  videoEmbed: {
    marginTop: '100px',
    height: 'calc(100vw * 9 / 16)',

    [BREAKPOINTS.MOBILE]: {
      marginTop: '80px',
    },
  },
  iFrame: {
    width: '100%',
    height: 'calc(100vw * 9 / 16)',
    marginBottom: 0,
  },
  embedWebGl: {
    width: '50vw',
    overflow: 'hidden',
    border: 'none',
    background: 'none',
    height: '63.5vw',
    [BREAKPOINTS.MOBILE]: {
      width: '100vw',
      height: '100vw',
    },
  },
});

export const query = graphql`
  query($articleName: String!) {
    contentfulArticleObject(name: { eq: $articleName }) {
      id
      slug
      name
      subheader
      ctaText
      mainImage {
        fluid(maxWidth: 3000, quality: 100, sizes: "3000px") {
          ...GatsbyContentfulFluid
        }
      }
      contentItems {
        largeText {
          largeText
        }
        smallText {
          smallText
        }
        quote {
          quote
        }
        fullWidthImage {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
        leftImage {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
        rightImage {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
        centerImage {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
        imageCollection {
          id
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
        sideBySide {
          id
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
        fourShot {
          id
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
        link
        shopLinkSide
        authour
        youtubeId
        threedmodel
      }
    }

    contentfulArticleContent {
      id
      title
      largeText {
        largeText
      }
      smallText {
        smallText
      }
      quote {
        quote
      }
      fullWidthImage {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      leftImage {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      rightImage {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      centerImage {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      imageCollection {
        id
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      sideBySide {
        id
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      fourShot {
        id
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      link
      shopLinkSide
      authour
      youtubeId
      threedmodel
    }
  }
`;
